import styled, { css } from 'styled-components';

import type { ViewOrderStatus } from '../enums';

const baseHeadingStyles = css`
  color: var(--colors-on-surface-a);
  line-height: 1.2;
  margin: 0;
`;

const OrderStatusWrapper = styled.div<{
  $orderStatus: `${ViewOrderStatus}`;
}>`
  border-radius: var(--radii-md);
  display: flex;
  font-size: var(--font-sizes-14);
  gap: var(--spaces-2xs);
  justify-content: center;
  line-height: var(--line-heights-half);
  padding: var(--spaces-xs) var(--spaces-s);

  ${({ $orderStatus }) => {
    switch ($orderStatus) {
      case 'Partially Refunded':
      case 'Refunded': {
        return css`
          background: var(--colors-surface-high);
          color: var(--colors-on-surface-b);
        `;
      }
      case 'Cancelled': {
        return css`
          background: var(--colors-error-lowest);
          color: var(--colors-error-on-lowest);
        `;
      }
      default: {
        return css`
          background: var(--colors-surface-high);
          color: var(--colors-on-surface-b);
        `;
      }
    }
  }}
`;

const Text = styled.p<{
  $centered?: boolean;
  $color?: 'on-surface-a' | 'on-surface-b' | 'on-surface-c';
  $weight?: 'bold' | 'normal';
}>`
  color: ${(props) =>
    !props.$color
      ? `var(--colors-on-surface-a)`
      : `var(--colors-${props.$color})`};
  font-weight: ${(props) =>
    props.$weight ? `var(--font-weights-${props.$weight})` : ''};
  line-height: 1.2;
  margin: 0;
  text-align: ${(props) => (props.$centered ? 'center' : 'left')};
`;

const TextXS = styled(Text)`
  font-size: var(--font-sizes-12);
`;

const TextS = styled(Text)`
  font-size: var(--font-sizes-14);
`;

const TextL = styled(Text)`
  font-size: 1.125rem;
`;

const Heading = styled.h3`
  ${baseHeadingStyles};
  font-size: 1.75rem;
  font-weight: var(--font-weights-normal);
  text-align: center;
`;

const SubHeading = styled.h4`
  ${baseHeadingStyles};
  font-size: var(--font-sizes-16);
  font-weight: var(--font-weights-bold);
`;

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: var(--spaces-l);
  width: 100%;
`;

const DetailsHeader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: var(--spaces-m);
  margin-bottom: 2.25rem;
`;

const Bold = styled.span`
  font-weight: var(--font-weights-bold);
`;

const DetailsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spaces-xs);
  padding-bottom: 2.25rem;
`;

const OrderMeta = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const OrderMetaData = styled.li``;

const SummaryWrapper = styled.div`
  border-top: 1px solid var(--colors-on-surface-d);
  display: flex;
  flex-direction: column;
  gap: var(--spaces-l);
  margin-bottom: 2.25rem;
  padding-top: 2.25rem;
`;

const SummaryRow = styled.div<{ $isSubRow?: boolean }>`
  display: flex;
  gap: var(--spaces-xl);
  justify-content: space-between;
  padding-left: ${(props) => (props.$isSubRow ? '1.25rem' : '0')};
`;

const SummaryGroup = styled.ul<{ $gap?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ $gap }) => ($gap ? 'var(--spaces-l)' : '')};
  list-style: none;
  margin: 0;
  padding: 0;
`;

const PaymenySummaryGroup = styled(SummaryGroup)`
  gap: var(--spaces-2xs);
`;

const PaymentGroup = styled.li`
  display: flex;
  flex-direction: column;
  gap: var(--spaces-2xs);
`;

const PaymentHeader = styled.div`
  align-items: center;
  display: flex;
  gap: var(--spaces-2xs);
`;

const PendingMessage = styled(TextXS)`
  color: var(--colors-on-surface-c);
`;

const ReceiptMessage = styled(TextS)`
  color: var(--colors-on-surface-c);
`;

const TotalPaidGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spaces-2xs);
`;

const TotalPaidDate = styled.span`
  color: var(--colors-on-surface-c);
`;

export const Styled = {
  Bold,
  DetailsHeader,
  DetailsSection,
  DetailsWrapper,
  Heading,
  OrderMeta,
  OrderMetaData,
  OrderStatusWrapper,
  PaymentGroup,
  PaymentHeader,
  PaymenySummaryGroup,
  PendingMessage,
  ReceiptMessage,
  SubHeading,
  SummaryGroup,
  SummaryRow,
  SummaryWrapper,
  Text,
  TextL,
  TextS,
  TextXS,
  TotalPaidDate,
  TotalPaidGroup,
};
