import { ButtonSpinner, Divider } from '@/components';
import { useGiftCardsStore } from '@/store';

import styles from './add-gift-card-form-footer.module.css';

export function AddCardFormFooter() {
  const isAddGiftCardPending = useGiftCardsStore().use.isAddGiftCardPending();

  const submitButtonText = isAddGiftCardPending ? 'Loading' : 'Add gift card';

  return (
    <div className={styles['add-gift-card-form-footer']}>
      <Divider />

      <ButtonSpinner
        disabled={isAddGiftCardPending}
        level="primary"
        loading={isAddGiftCardPending}
        size="medium"
        spinnerColor={'var(--colors-on-surface-c)'}
        type="submit"
        variant="branded"
      >
        {submitButtonText}
      </ButtonSpinner>
    </div>
  );
}
