import styled, { css } from 'styled-components';

const SearchContainer = styled.form<{ $size?: 'medium' | 'small' }>`
  align-items: flex-end;
  display: grid;
  gap: var(--spaces-xs);
  justify-content: flex-end;
  margin-bottom: 0;
  width: 100%;

  > button {
    height: ${({ $size }) => ($size === 'small' ? '2.5rem' : 'auto')};

    @media (width >= 48rem) {
      margin-bottom: var(--spaces-m);
    }
  }

  @media (width >= 80rem) {
    grid-template-columns: minmax(32%, 200px) minmax(32%, 200px) auto auto;
    justify-content: flex-end;
  }

  @media (width >= 64rem) and (width <= 80rem) {
    grid-template-columns: minmax(32%, 200px) minmax(32%, 200px) auto auto;
    justify-content: flex-start;
  }

  @media (width <= 64rem) {
    grid-template-columns: minmax(32%, 200px) minmax(32%, 200px) auto auto;
    justify-content: flex-start;
  }

  @media (width <= 30rem) {
    grid-template-columns: 1fr;
  }
`;

const SearchError = styled.div<{ $size?: 'medium' | 'small' }>`
  align-items: center;
  bottom: ${(props) =>
    props.$size === 'small' ? '-1.25rem' : 'calc(var(--spaces-xs) * -1)'};
  color: var(--colors-error-on-lowest);
  display: flex;
  font-size: ${(props) =>
    props.$size === 'small' ? 'var(--font-sizes-12)' : 'var(--font-sizes-14)'};
  gap: var(--spaces-2xs);
  line-height: var(--line-heights-half);
  position: absolute;
`;

const SearchInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spaces-xs);
  position: relative;
`;

const SearchLabel = styled.label`
  font-size: var(--font-sizes-14);
  font-weight: var(--font-weights-bold);
  line-height: var(--line-heights-half);
`;

const SearchInputContainer = styled.div<{
  $disabled?: boolean;
  $error?: boolean;
  $size?: 'medium' | 'small';
}>`
  align-items: center;
  background: var(--colors-surface-lowest);
  border: 1px solid var(--colors-on-surface-c);
  border-radius: var(--radii-md);
  display: flex;
  gap: var(--spaces-2xs);
  height: ${({ $size }) => ($size === 'small' ? '2.5rem' : 'auto')};
  justify-content: center;
  padding: ${(props) =>
    props.$size === 'small'
      ? 'var(--spaces-xs) var(--spaces-s)'
      : 'var(--spaces-s)'};
  width: 100%;
  ${({ $error }) =>
    $error &&
    css`
      border-color: var(--colors-error-on-lowest);
      margin-bottom: var(--spaces-m);
    `}

  &:focus-within {
    border-color: ${(props) =>
      props.$error
        ? 'var(--colors-error-on-lowest)'
        : 'var(--colors-on-surface-a)'};
    outline: 2px solid var(--colors-focus-light);
    outline-offset: 2px;
  }

  ${(props) =>
    props.$disabled &&
    'background: var(--colors-surface-low); border-color: var(--colors-on-surface-d); cursor: not-allowed;'}

  &:hover {
    ${(props) =>
      !props.$disabled &&
      !props.$error &&
      'border-color: var(--colors-on-surface-b);'}
  }

  @media (width >= 48rem) {
    margin-bottom: var(--spaces-m);
  }
`;

const ClearButton = styled.button`
  align-items: center;
  background: none;
  border: 0;
  cursor: pointer;
  display: flex;
  margin: 0;
  padding: 0;

  &:disabled {
    cursor: not-allowed;
  }
`;

const SearchInput = styled.input<{ $size?: 'medium' | 'small' }>`
  border: 0;
  color: var(--colors-on-surface-a);
  font-size: ${({ $size }) =>
    $size === 'small' ? 'var(--font-sizes-14)' : 'var(--font-sizes-16)'};
  font-style: normal;
  font-weight: var(--font-weights-normal);
  line-height: var(--line-heights-half);
  width: 100%;

  &::placeholder {
    color: var(--colors-on-surface-d);
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    background: var(--colors-surface-low);
    color: var(--colors-on-surface-d);
    cursor: not-allowed;
  }
`;

export const Styled = {
  ClearButton,
  SearchContainer,
  SearchError,
  SearchInfo,
  SearchInput,
  SearchInputContainer,
  SearchLabel,
};
