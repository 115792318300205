import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  ButtonSpinner,
  Divider,
  ErrorDialog,
  Form,
  SideSheet,
  SuccessDialog,
  TextField,
} from '@/components';
import { genericContent } from '@/config/language';
import { ERROR_VERIFY_EMAIL } from '@/config/language/errors';
import {
  profileContent,
  profileDialog,
  useEmailInitiateChange,
  useEmailVerify,
  useGetProfile,
} from '@/features/profile';
import { useAnalytics } from '@/hooks';
import { BaasErrors } from '@/services';
import { APIErrors } from '@/services/errors';
import { validate } from '@/utils';

import { ErrorMessage } from './error-message';
import { Styled } from './styles';

import type { TBaasError } from '@/services';
import type { FieldValues } from 'react-hook-form';

export enum FormState {
  EMAIL = 'email',
  VERIFICATION_CODE = 'verification_code',
}

const DEFAULT_EMAIL_FORM_VALUES = {
  email: '',
} as FieldValues;

const DEFAULT_VERIFICATION_FORM_VALUES = {
  verification_code: '',
} as FieldValues;

const emailValidationSchema = Joi.object({
  email: validate.email(),
}).unknown(true);

const verificationCodeValidationSchema = Joi.object({
  verification_code: validate.verificationCodeEmail(),
}).unknown(true);

const getErrorMessage = (errorNumber: number | undefined): JSX.Element => {
  const errorString = errorNumber?.toString();
  switch (errorString) {
    case BaasErrors.PING_PATCH_USER_ERROR: {
      return <>That email address isn’t available.</>;
    }
    case BaasErrors.UPDATE_EMAIL_VERIFY_INVALID_CODE_ERROR: {
      return <>That code is incorrect or has expired.</>;
    }
    default: {
      return <>We’re sorry something went wrong.</>;
    }
  }
};

type TUpdateEmailSheet = {
  onClose: () => void;
  open: boolean;
  reload: () => void;
};

export const UpdateEmailSheet = ({
  onClose,
  open,
  reload,
}: TUpdateEmailSheet) => {
  const { track } = useAnalytics();
  const { data: user } = useGetProfile();
  const codeRef = useRef<HTMLInputElement>(null);

  const [updateState, setEmailFormState] = useState(FormState.EMAIL);
  const [email, setEmail] = useState('');
  const [isOpenSuccess, setIsOpenSuccess] = useState(false);
  const [isOpenError, setIsOpenError] = useState(false);
  const [errorText, setErrorText] = useState<JSX.Element | string>();

  const formMethods = useForm({
    defaultValues:
      updateState === FormState.EMAIL
        ? DEFAULT_EMAIL_FORM_VALUES
        : DEFAULT_VERIFICATION_FORM_VALUES,
    reValidateMode: 'onChange',
    resolver: joiResolver(
      updateState === FormState.EMAIL
        ? emailValidationSchema
        : verificationCodeValidationSchema
    ),
  });

  const onInitiateSuccess = () => {
    formMethods.reset();
    setEmailFormState(FormState.VERIFICATION_CODE);
    setTimeout(() => {
      codeRef.current?.focus();
    }, 100);
  };

  const onInitiateError = (error: TBaasError) => {
    const statusCode = error?.response?.status;
    if (statusCode === APIErrors.CONFLICT) {
      setErrorText('That email address isn’t available.');
    } else {
      setIsOpenError(true);
    }
    setTimeout(() => {
      codeRef.current?.focus();
    }, 100);
  };

  const emailInitiateChange = useEmailInitiateChange({
    onError: onInitiateError,
    onSuccess: onInitiateSuccess,
  });

  const onVerifyError = (error: TBaasError) => {
    track('Email Validation Failed');
    const statusCode = error?.response?.status;
    if (statusCode === APIErrors.BAD_REQUEST) {
      setErrorText(
        getErrorMessage(error.response?.data?.details?.error_number)
      );
    } else {
      setIsOpenError(true);
    }
    setTimeout(() => {
      codeRef.current?.focus();
    }, 100);
  };

  const onVerifySuccess = () => {
    track('Update Email Confirmed');
    setIsOpenSuccess(true);
  };

  const emailVerify = useEmailVerify({
    onError: onVerifyError,
    onSuccess: onVerifySuccess,
  });

  const onSubmitForm = async ({ email, verification_code }: FieldValues) => {
    updateState === FormState.EMAIL
      ? emailInitiateChange.mutate({
          email,
          flow_type: 'otp',
        })
      : emailVerify.mutate({
          verification_code,
        });
  };

  const onCloseSuccessDialog = () => {
    setIsOpenSuccess(false);
    formMethods.reset();
    setEmailFormState(FormState.EMAIL);
    reload();
  };

  const onCloseErrorDialog = () => {
    setIsOpenError(false);
    setErrorText('');
  };

  const onCloseSideSheet = () => {
    onClose();
    formMethods.reset();
    setEmailFormState(FormState.EMAIL);
    setErrorText('');
  };

  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (updateState === FormState.EMAIL) {
      setEmail(e.target.value);
    }
    setErrorText('');
  };

  const isSubmitting = emailInitiateChange.isPending || emailVerify.isPending;

  return (
    <>
      <SideSheet
        onClose={onCloseSideSheet}
        open={open}
        subtitle={
          updateState === FormState.EMAIL
            ? profileContent.UPDATE_EMAIL_MESSAGE(
                user?.current_wallet_id?.toLocaleLowerCase()
              )
            : profileContent.VERIFY_EMAIL_MESSAGE(email)
        }
        title={
          updateState === FormState.EMAIL
            ? profileContent.UPDATE_EMAIL_TITLE
            : profileContent.VERIFY_EMAIL_TITLE
        }
      >
        <Styled.UpdateContentWrapper>
          <Form config={formMethods} onSubmitForm={onSubmitForm}>
            <Styled.Fieldset>
              {updateState === FormState.EMAIL ? (
                <TextField
                  disabled={!!isSubmitting}
                  label="New email"
                  name="email"
                  onChange={handleChangeEmail}
                  placeholder="email@internet.com"
                  ref={codeRef}
                />
              ) : (
                <TextField
                  disabled={!!isSubmitting}
                  label="Verification code"
                  maxLength={8}
                  name="verification_code"
                  onChange={handleChangeEmail}
                  placeholder="••••••••"
                  ref={codeRef}
                />
              )}
              {!!errorText && <ErrorMessage text={errorText} />}
            </Styled.Fieldset>
            <Styled.Footer>
              <Divider />
              <ButtonSpinner
                disabled={isSubmitting}
                level="primary"
                loading={isSubmitting}
                size="medium"
                spinnerColor={'var(--colors-on-surface-c)'}
                type="submit"
                variant="branded"
              >
                {isSubmitting ? 'Loading' : 'Continue'}
              </ButtonSpinner>
            </Styled.Footer>
          </Form>
        </Styled.UpdateContentWrapper>
        {isOpenError && (
          <ErrorDialog
            isOpen={isOpenError}
            message={ERROR_VERIFY_EMAIL}
            onOpenChange={onCloseErrorDialog}
            title={genericContent.ERROR_GENERIC_TITLE}
          />
        )}
        {isOpenSuccess && (
          <SuccessDialog
            buttonText={profileDialog.UPDATE_EMAIL_SUCCESS_BUTTON}
            description={profileDialog.UPDATE_EMAIL_SUCCESS_MESSAGE}
            isOpen={isOpenSuccess}
            onOpenChange={onCloseSuccessDialog}
            title={profileDialog.UPDATE_EMAIL_SUCCESS_TITLE}
          />
        )}
      </SideSheet>
    </>
  );
};
