import { MerchantLogo } from '@/features/activity';
import { V2QrEnums } from '@/services';
import { formatCurrency } from '@/utils/format/currency';

import { ViewOrderStatus } from '../enums';

import { OrderStatusCard } from './order-status-card';
import { PaymentSummary } from './payment-summary';
import { Styled } from './styles';

import type { OrderTypes } from '@/features/activity/types';

interface IOrderDetailsProps {
  order: OrderTypes.TOrderInfo;
  orderId?: string;
}

export const OrderDetails = ({ order }: IOrderDetailsProps) => {
  const {
    amount,
    created_at,
    hasMyColes,
    merchantId,
    merchantLocation,
    merchantName,
    orderId,
    orderReference,
    orderStatus,
    paymentContext,
    payments,
  } = order;

  const { line1, line2, line3 } = merchantLocation || {};

  const orderDate = !!created_at.dayMonthTime ? created_at.dayMonthTime : '-';

  return (
    <Styled.DetailsWrapper>
      <Styled.DetailsHeader>
        <MerchantLogo
          merchantId={merchantId}
          merchantName={merchantName}
          size="lg"
        />
        <Styled.Heading>
          <Styled.Bold>{formatCurrency(amount)}</Styled.Bold>
          {orderStatus !== ViewOrderStatus.PENDING ? ' paid to' : ' payment to'}
          <br />
          <Styled.Bold>{merchantName}</Styled.Bold>
        </Styled.Heading>
        <Styled.OrderMeta>
          <Styled.OrderMetaData>
            <Styled.TextS $centered $color="on-surface-b">
              {`Order date: ${orderDate}`}
            </Styled.TextS>
          </Styled.OrderMetaData>
          <Styled.OrderMetaData>
            <Styled.TextS $centered $color="on-surface-b">
              {`Order ID: ${orderReference ?? orderId}`}
            </Styled.TextS>
          </Styled.OrderMetaData>
        </Styled.OrderMeta>
        <OrderStatusCard orderStatus={orderStatus} />
      </Styled.DetailsHeader>

      {merchantLocation &&
        paymentContext === V2QrEnums.PaymentContext.IN_STORE && (
          <Styled.DetailsSection>
            <Styled.Text $weight="bold">Store</Styled.Text>
            <Styled.Text>
              {!!line1 && (
                <>
                  {line1}
                  <br />
                </>
              )}
              {!!line2 && (
                <>
                  {line2}
                  <br />
                </>
              )}
              {!!line3 && line3}
            </Styled.Text>
          </Styled.DetailsSection>
        )}
      <PaymentSummary
        amount={amount}
        hasMyColes={hasMyColes}
        orderStatus={orderStatus}
        payments={payments}
      />
    </Styled.DetailsWrapper>
  );
};
