import styled from 'styled-components';

const HeaderContainer = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spaces-m);
  width: 100%;
`;

const HeaderRow = styled.div<{ $stacked?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: var(--spaces-m);
  justify-content: space-between;
  width: 100%;

  @media (width >= 80rem) {
    flex-direction: row;
  }
`;

const HeaderDetailsWrapping = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spaces-m);
  width: 100%;

  @media (width >= 48rem) {
    gap: 18px;
  }
`;

const SearchWrapper = styled.div`
  width: 100%;
`;

const SubHeading = styled.h2`
  color: #333;
  font-family: var(--fonts-sans), sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: var(--font-weights-normal);
  line-height: var(--line-heights-half);
  margin: 0;

  @media (width >= 48rem) {
    font-size: 18px;
  }
`;

const TableWrapper = styled.div`
  flex: 0 0 80%;
`;

const ActivityWrapper = styled.div`
  @media (width >= 90rem) {
    max-width: 67.5rem;
  }
`;

export const Styled = {
  ActivityWrapper,
  HeaderContainer,
  HeaderDetailsWrapping,
  HeaderRow,
  SearchWrapper,
  SubHeading,
  TableWrapper,
};
