import { AddIcon } from '@/components/icons';
import { Breakpoints } from '@/config/breakpoints';
import { LogoCard } from '@/features/my-coles';
import { useWindowSize } from '@/hooks';
import { useHideInProduction } from '@/hooks';

import { Styled } from '../../styles';

import type { LegacyRef } from 'react';

interface IMycolesNotLinked {
  buttonRef: LegacyRef<HTMLButtonElement>;
  handleOpenAddCard: () => void;
}

export function MycolesNotLinked({
  buttonRef,
  handleOpenAddCard,
}: IMycolesNotLinked) {
  const hideInProduction = useHideInProduction();
  const windowSize = useWindowSize();
  const myColesCardSize =
    !!windowSize?.width && windowSize.width > Breakpoints.xxl ? 'md' : 'sm';

  return (
    <Styled.LoyaltyCardItemContainer>
      <div>
        <LogoCard size={myColesCardSize} type="mycoles" />
      </div>
      <Styled.LoyaltyInfoWrapper>
        <h3>mycoles discount card</h3>
        <p>
          Link your mycoles discount card to get your team member discounts.
        </p>
        <Styled.LinkButton
          $isNonProd={hideInProduction}
          onClick={handleOpenAddCard}
          ref={buttonRef}
        >
          <AddIcon height={16} width={16} />{' '}
          <span>Link mycoles discount card</span>
        </Styled.LinkButton>
      </Styled.LoyaltyInfoWrapper>
    </Styled.LoyaltyCardItemContainer>
  );
}
