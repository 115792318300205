import { useState } from 'react';

import { ButtonSpinner } from '@/components';
import { useBankCardsStore } from '@/store';

import { BankCardArt } from '../../components/bank-card-art/bank-card-art';
import { useDeleteBankCard } from '../../services/use-delete-bank-card/use-delete-bank-card';
import { useSetDefaultBankCard } from '../../services/use-set-default-bank-card/use-set-default-bank-card';
import { getBankCardLabel } from '../../utils/get-bank-card-label';

import { Styled } from './styles';

import type { BankCardsTypes } from '@/features/bank-cards';

type TCardListDialogBodyProps = {
  cards?: BankCardsTypes.Components.TBankCards;
};

export function CardListDialogBody({ cards }: TCardListDialogBodyProps) {
  const [newDefaultCard, setNewDefaultCard] =
    useState<BankCardsTypes.Components.TBankCard>();

  const selectedBankCard = useBankCardsStore().use.selectedBankCard();

  const onClickCard = (card: BankCardsTypes.Components.TBankCard) => () => {
    setNewDefaultCard(card);
  };

  const handleSetDefaultCard = () => {
    newDefaultCard && setDefaultCard.mutate();
  };

  const deleteCard = useDeleteBankCard(selectedBankCard?.id || '');

  const setDefaultCard = useSetDefaultBankCard(
    (newDefaultCard && newDefaultCard?.id) || '',
    {
      onSuccess: () => {
        deleteCard?.mutate();
      },
    }
  );

  const isBankCardRemovalPending =
    setDefaultCard.isPending || deleteCard.isPending;

  return (
    <Styled.DialogBodyCardListContainer>
      <Styled.DialogBodyCardListWrapper>
        {cards?.map((card: BankCardsTypes.Components.TBankCard) => {
          return (
            <Styled.DialogBodyCardListItemWrapper key={card.id}>
              <Styled.DialogBodyCardListItem
                $selected={newDefaultCard?.id === card.id}
                aria-label={getBankCardLabel(card)}
                onClick={onClickCard(card)}
                role="button"
                tabIndex={0}
              >
                <Styled.DialogBodyCardListPaymentCardWrapper>
                  <BankCardArt
                    cardType={card.data.type}
                    schemes={card.data.schemes}
                    size="sm"
                  />
                </Styled.DialogBodyCardListPaymentCardWrapper>

                <Styled.DialogBodyCardListItemTextWrapper>
                  {card.alias || card.data.issuer_name}
                  <p>••• {card.data.visualization.last_four_digits}</p>
                </Styled.DialogBodyCardListItemTextWrapper>
              </Styled.DialogBodyCardListItem>
            </Styled.DialogBodyCardListItemWrapper>
          );
        })}
      </Styled.DialogBodyCardListWrapper>

      <ButtonSpinner
        disabled={isBankCardRemovalPending}
        level="primary"
        loading={isBankCardRemovalPending}
        onClick={handleSetDefaultCard}
        size="large"
        spinnerColor={'var(--colors-on-surface-e)'}
        variant="neutral"
      >
        {isBankCardRemovalPending ? 'Loading' : 'Continue'}
      </ButtonSpinner>
    </Styled.DialogBodyCardListContainer>
  );
}
